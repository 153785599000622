<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="`Status betaling #${reservationID}`"
    />

    <div class="row justify-content-md-center">
      <div class="col-md-auto col-md-5">
        <b-card v-if="paymentData" class="payment-card text-center">
          <b-card-text>
            <div class="icon-payment-status text-center">
              <font-awesome-icon
                v-if="!paid"
                class="text-danger"
                far
                :icon="['far', 'times-circle']"
              />
              <font-awesome-icon
                v-else
                class="text-success"
                far
                :icon="['far', 'check-circle']"
              />
            </div>
            <h1 class="text-center">{{ paymentStatusMessage }}</h1>

            <hr />

            <div>
              <strong>Betaalgegevens</strong>
            </div>

            <table class="table">
              <tbody>
                <tr>
                  <td class="text-left">Reservering/Bestelling</td>
                  <td class="text-right text-primary">
                    <strong>#{{ reservationID }}</strong>
                  </td>
                </tr>
                <tr v-if="paymentData.CreatedBy">
                  <td class="text-left">Bestelling gedaan door</td>
                  <td class="text-right text-primary">
                    <strong>{{ paymentData.CreatedBy }}</strong>
                  </td>
                </tr>
                <tr v-if="paymentData.TotalIncVAT">
                  <td class="text-left">Totaal te voldoen</td>
                  <td class="text-right">
                    <strong
                      >&euro;
                      {{
                        (Math.round(paymentData.TotalIncVAT * 100) / 100)
                          .toFixed(2)
                          .replace('.', ',')
                      }}</strong
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Openstaand bedrag</td>
                  <td class="text-right">
                    <strong
                      >&euro;
                      {{
                        (Math.round(paymentData.TotalAmountDue * 100) / 100)
                          .toFixed(2)
                          .replace('.', ',')
                      }}</strong
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="!paid">
              <hr />
              <div class="text-left">
                <b-form-checkbox
                  id="agree-with-terms"
                  v-model="terms"
                  name="agree-with-terms"
                  value="accepted"
                  class="text-left mb-3"
                  :required="true"
                  :unchecked-value="false"
                >
                  Ik ga akkoord met
                  <a
                    href="https://www.west-vlaanderen.be/uitleendienst-reglement"
                    target="_blank"
                    >reglement</a
                  >
                  van de provinciale uitleendienst
                  <span class="text-danger">*</span>
                </b-form-checkbox>
              </div>
              <p class="text-grey">
                <small>
                  Creditcard betalingen worden direct belast op de creditcard
                </small>
              </p>
              <a
                :href="paymentLink"
                :class="{
                  btn: true,
                  'btn-primary': true,
                  'd-block': true,
                  disabled: terms === false
                }"
                >Nu afrekenen</a
              >
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>

    <b-card class="mt-5">
      <div class="row">
        <div class="col">
          <h2 class="pt-2">Klaar met je bestelling?</h2>
        </div>
        <div class="text-right col">
          <a href="/" class="btn btn-primary"
            ><font-awesome-icon fas icon="chevron-left" /> Verder winkelen</a
          >
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import {
  getPaymentStatus,
  updatePaymentStatus
} from '@/services/ReservationService'
export default {
  name: 'PaymentStatus',
  data() {
    return {
      breadCrumbs: [
        { page: 'index', title: 'Uitleendienst', path: '/' },
        { page: 'account', title: 'Mijn account', path: '/account' }
      ],
      paymentData: null,
      terms: false
    }
  },
  computed: {
    reservationID: function() {
      return this.$route.params.OrderReservationID
    },
    secretCode: function() {
      return this.$route.query.secretCode
    },
    payID: function() {
      return this.$route.query.PAYID
    },
    orderID: function() {
      return this.$route.params.OrderReservationID
    },
    paymentStatus: function() {
      return this.$route.query.STATUS
    },
    status: function() {
      if (this.paymentData) return this.paymentData.Status
      return null
    },
    paid: function() {
      if (this.status === 'Not paid' || this.status === null) return false
      return true
    },
    paymentLink: function() {
      if (this.paymentData && !this.paid) return this.paymentData.PaymentLink
      return null
    },
    paymentStatusMessage: function() {
      if (this.paid) return 'Betaling ontvangen'
      return 'Betaling niet voldaan'
    },
    redirectedFromPaymentProvider: function() {
      if (
        this.secretCode &&
        this.payID &&
        this.orderID &&
        this.paymentStatus == 9
      )
        return true
      return false
    }
  },
  created: async function() {
    if (this.redirectedFromPaymentProvider) {
      await this.updatePaymentStatus()
    }

    await this.setPaymentData()
  },
  methods: {
    updatePaymentStatus: async function() {
      await updatePaymentStatus({
        secretCode: this.secretCode,
        payID: this.payID,
        orderID: this.orderID,
        status: this.paymentStatus
      })
    },
    setPaymentData: async function() {
      const result = await getPaymentStatus({
        reservationID: this.reservationID
      })
      this.paymentData = result.data
    }
  }
}
</script>
<style lang="scss">
.payment-card {
  td {
    border: none;
  }
  h1 {
    margin-bottom: 0.75rem;
    font-size: 27px;
  }
  .icon-payment-status {
    font-size: 80px;
  }
}
</style>
